:root {
    --color-ontrial-blue: #1C4A96;
    --color-ontrial-green: #74ad1b;
    --color-dark-blue: #0f336b;
    --color-light-blue: #abe1fa;
    --color-light-gray: #B6B6B6;
    --color-gradient-gray: #c0c0c0;
    --color-dark-gray: #585858;
    --color-middle-gray: #646464;
    --color-shadow-gray: #a1a1a1;
    --color-white: #ffffff;
    --color-transparent-white: rgba(255, 255, 255, 0.75);
    --color-lighter-gray: #dddddd;
    --color-lightest-gray: #f5f5f5;
    --color-ontrial-red: #d62626;
    --font-family-primary: 'proxima-nova', sans-serif;
    --font-weight-light: 210;
    --font-weight-regular: 400;
    --font-weight-heavy: 600;
    --font-size-xs: 10pt;
    --font-size-small: 12pt;
    --font-size-regular: 14pt;
    --font-size-large: 18pt;
    --font-size-h1: 22pt;
    --font-size-h2: 20pt;
    --font-size-h3: 18pt;
    --font-size-h4: 16pt;
    --shadow-middle: 0em .15em 0.4em 0.0005em var(--color-shadow-gray);
    --shadow-lifted: 0em .15em 0.5em 0.0008em var(--color-middle-gray);
    --shadow-light: 0em .15em 0.18em 0.0005em var(--color-lighter-gray);
    --shadow-card-panel-border: inset 0em 0em 0em 5em var(--color-middle-gray);
    --padding-light: 0.5rem;
    --letter-spacing-small: .07rem;
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

* {
    font-family: var(--font-family-primary);
}

body {
    margin: 0px;
    padding: 0px;
}

html, body, #root {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.padding-light {
    padding: var(--padding-light);
}

div.connect-block {
    display: inline-block;
    max-width: 45%;
    min-width: 30% ;
}

h1 {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-heavy);
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

h2 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-heavy);
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

h3 {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-heavy);
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

h4 {
    font-size: 12pt;
    /* font-weight: var(--font-weight-heavy); */
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

p {
    font-size: var(--font-size-regular);
}

a {
    color: var(--color-ontrial-blue);
}

.ontrial-h1 {
    font-size: 18pt;
    font-weight: 600;
}

.ontrial-h2 {
    font-size: 14pt;
    font-weight: 600;
}

.ontrial-subtitle {
    font-size: 12pt;
    font-weight: 400;
    color: #858585;
}

.ontrial-copy {
    font-size: 11pt;
    font-weight: 200;
}

.ontrial-info {
    font-size: 10pt;
    font-weight: 300;
    color: #858585;
}

button {
    font-size: var(--font-size-small);
    background: #ffffff;
    border: 1px solid var(--color-lighter-gray);
    padding: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .25rem;
}

button:hover {
    box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    -webkit-box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    -moz-box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    outline: none;
}

button:focus {
    box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    -webkit-box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    -moz-box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    outline: none;
}

button:active {
    box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    -webkit-box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    -moz-box-shadow: 0rem 0.125rem .25rem .125rem var(--color-light-gray);
    outline: none;
}

label {
    font-size: var(--font-size-small)
}

b {
    font-size: var(--font-size-small)
}
.sign-up-select {
    min-width:16rem;
    max-width: 240px!important;
    margin: auto!important;
}
select.form-input {
    background:
        linear-gradient(45deg, transparent 50%, black 50%),
        linear-gradient(135deg, black 50%, transparent 50%),
        linear-gradient(to right, white, white);
    background-position:
        calc(100% - 21px) calc(1em + 2px),
        calc(100% - 16px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
    border: thin solid var(--color-light-gray);
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    font-size: var(--font-size-xs);

    /* reset */
    border-radius: 3;
    margin: 0;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input {
    display: block;
    margin: auto;
    padding: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    font-size: var(--font-size-small);
    border-radius: 0.25rem;
    min-width: 16rem;
    border: solid 1px var(--color-middle-gray);
}

input:disabled{
    background: var(--color-lighter-gray);
}

select {
    display: block;
    margin: auto;
    font-size: 12px;
    padding: 0.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-bottom: 0.5rem;
    min-width: 16rem;
}

.light {
    font-weight: 100;
}

.subtitle {
    font-weight: 400;
    font-size: 12pt;
}

.card-bg-light {
    padding: 0.8rem;
    background-color: var(--color-lighter-gray);
    border: 0.04rem solid var(--color-middle-gray);
    border-radius: 0.5rem;
}

button.btn-green {
    background: var(--color-ontrial-green);
    color: var(--color-white);
    font-weight: 500;
}

button.btn-primary {
    background: var(--color-ontrial-blue);
    color: var(--color-white);
    font-weight: 500;
    border:none;
}

button.btn-secondary {
    background: var(--color-dark-gray);
    border: 1px solid var(--color-middle-gray);
    color: var(--color-white);
    font-weight: 500;

}


.logo-md {
    max-width: 350px;
    cursor: pointer;
}


@media only screen and ( max-width : 1000px ){
    .logo-md{
        max-height: 100px;
    }

}


.signup-bg {
    /* background-image: url("../App/vendor/media/background_login_lg.jpg"); */
    background-color: var(--color-white);
    height: 94%;
    position: sticky;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;

}
/* 
footer {
    width: 100%;
    max-height: 10px;
    padding-top: 98%;
    background-color: #1c479d;
} */

.indicator {
    border: 2px solid var(--color-ontrial-blue);
    border-radius: 1rem;
    max-width: 5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: 700;
    color: var(--color-ontrial-blue);
}

.text-meta {
    /* margin-left: 1rem; */
    font-size: var(--font-size-small);
    line-height: 10pt;
    font-weight: 300;
    margin-top: 0rem;
    margin-bottom: 0.25rem;
}

.corral {
    min-height: 520px;
    max-height: 100%;
}

.corral-scroll {
    height: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.corral-dash {
    height: 93%;
    max-height: 93%;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.text-regular {
    font-size: var(--font-size-regular);
}

.text-small {
    font-size: var(--font-size-small);
}

.text-large {
    font-size: var(--font-size-large);
}

.logo-full {
    max-width: 45%;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

@media only screen and (max-width: 1200px) {
    button {
        font-size: 9pt;
    }
}

.header-block {
    width: 100%;
    background: var(--color-dark-gray);
    height: 5px;
}

.plants-bg {
    height: 100%;
}

#side-menu-hr{
    margin-top: 1rem;
    margin-left: 0.5rem;
    width: 76%;
    border: 0 none;



}

img.plants {
    /*width: 600px;*/
    max-height: 175px;
    position: fixed;
    z-index: -10;
    bottom: 35px;
    left: 60px;
}

#dashboard-footer-block {
    background: var(--color-dark-gray);
    width: 100%;
    height: 40px;    
}

#login-footer-block {
    background: var(--color-dark-gray);    
    height: 40px;
    position: fixed;
    left: 0;
    bottom: 0;
    width:100%;
}
#signup-footer-block {
    background: var(--color-dark-gray);    
    height: 40px;
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width:100%;
}
img.signup-plants {
    width: 600px;
    /* position: fixed; */
    /* z-index: -10; */
    bottom: 35px;
    margin-left: 60px;
}

#connect-clio-matter-text {
    text-align: center;
}