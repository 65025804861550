#dashboard-header-block {
    height: 100%;
    width: 100%;
    background: #585858;
    max-height: 5px;
}

#dashboard-menu {
    padding-top: 0.5rem;
    z-index: 3;
    max-height: 100%;
    box-shadow: 4px 0px 10px -10px #4e4e4e;
}

#side-menu-hr {
border-top: 2px solid var(--color-dark-gray);
}
#dashboard-main {
    background-image: linear-gradient(var(--color-lightest-gray), var(--color-lighter-gray));
    max-height: 100%;
    max-height: 100%;
    overflow: hidden;
    z-index: 2;
}



.action-logout{
    font-size: var(--font-size-small);
}

.dashboard-sidebar-logo {
    max-width: 120px;
    margin-top: 15px;
    margin-bottom: 25px
}



#dashboard-sidebar-spacer {
    min-height: 65px;
}

.dashboard-sidebar-tab>* {
    font-weight: 400;
    cursor: pointer;
    z-index: 199;
    padding: .25rem .25rem .25rem 1rem;
    transition: border .25s;
}

.dashboard-sidebar-tab.active>* {
    font-weight: 600;
    border-left: solid 5px var(--color-ontrial-blue);
    color: var(--color-ontrial-blue);
    fill: var(--color-ontrial-blue);

}

.sidebar-icon {
    font-size: 14pt;
}

.sidebar-icon:active {
    fill: var(--color-ontrial-blue);
}

#dashboard-footer-image {
    /* position: fixed; */
    bottom: -10%;
    left: -8%;
    min-width: 60%;
    max-width: 90%;
    z-index: -10;
}

#dashboard-background-image {
    /* position: absolute; */
    opacity: 0.15;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
    min-width: 100%;
    z-index: 0;
}

@media only screen and (max-width : 900px) {
    #dashboard-header-block {
        grid-column: span 1 !important;
        grid-row: span 1 !important;
    }

    #dashboard-menu {
        grid-column: span 1 !important;
        grid-row: span 1 !important;
        /* height: 100%; */
    }

    #dasboard-main {
        grid-column: span 1 !important;
        grid-row: span 1 !important;
    }

    #dashboard-footer-block {
        grid-column: span 1 !important;
        grid-row: span 1 !important;
    }

    #dashboard-sidebar-spacer {
        display: none;
    }

    #dashboard-sidebar-tabs-container {
        display: flex;
        justify-content: space-evenly;
    }

    .dashboard-sidebar-tab {
        display: inline-block;
    }

    .dashboard-sidebar-tab>* {
        padding-left: 0rem;
        padding-bottom: 0.5rem;
        padding-left: 0.25rem;
        padding-right: 0.5rem;
    }

    .dashboard-sidebar-tab.active>* {
        font-weight: 600;
        border-left: solid 0px var(--color-dark-gray);
        border-bottom: solid 3px var(--color-dark-gray);
    }
}