.col-span-1{
    grid-column: span 1;
}
.col-span-2{
    grid-column: span 2;
}

.row-span-1{
    grid-row: span 1;
}

.row-span-2{
    grid-row: span 2;
}

#dashboard-container {
    /* background-color: black; */
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: 5px auto 35px;
    grid-template-columns: 175px auto;
}

@media only screen and ( max-width : 900px ){
    #dashboard-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 15px 180px auto 45px;
        grid-template-columns: 100% !important;
        /* grid-template-columns: 175px auto; */
    }
}