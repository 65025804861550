#account-container {
    width: 100%;
    height: 100%;
    padding: 8%;
    padding-top: 4%;
    overflow-y: scroll;
}
#my-account-hr {
    border-top: 2px solid var(--color-dark-gray);

}
#account-info-form {
    display: grid;
    grid-template-columns: 50% 10% 40%;
    grid-template-rows:auto auto auto auto;
}
.connectButton {
    margin: auto 0 auto auto;
    align-content: right;
    display:inline-block;
}

.clio-logo {
    /* margin: auto auto auto 0; */
    /* height:100%; */
    /* margin-right: 4rem; */
    max-width: 240px;
}
.connection-info-text{
    padding-left: 2rem;
}
.integration-card {
    min-height: 100%!important;
    display: grid;
    grid-template-columns: 25% 63% 12%;
}

.account-form-input {
    margin: 0.25rem;
    min-width: 0rem;
    padding: 0.5rem;

}

.form-half {
    grid-column: span 1;
    grid-row: span 1;
}

.form-half-latter {
    grid-column: span 2;
    grid-row: span 1;
}

.form-full {
    grid-column: span 3;
    grid-row: span 1;
}

.form-partial {
    grid-column: span 1;
    grid-row: span 1;
}
