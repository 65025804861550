#form-wrapper {
    position: relative;
    z-index: 256;
}



.form-case-client-details {
    border-radius: 0.75em;
    background: var(--color-lightest-gray);
    padding: 2rem;
    padding-top: 0.5rem;
}

.form-order-meta {
    font-size: var(--font-size-xs);
    line-height: 12pt;
}

.form-input {
    margin-left: 0rem;
    z-index: 12 !important;
    border-color: var(--color-light-gray)
}

.form-client-meta-group {
    display: grid;
    grid-template-columns: 30% 70%;
}

.form-client-meta {
    background: var(--color-lightest-gray);
    outline: 0em;
    border: 0em;
    border-radius: 0em;
    border-bottom: solid 1px var(--color-light-gray);
    max-width: 100%;
    min-width: 80%;
    margin-left: 0em;
    display: inline-block;
}

.form-client-meta-label {
    font-weight: 300;
    display: inline-block;
    font-size: var(--font-size-xs);
    margin-top: 0.75rem;
    /* min-width: 15%; */
}

.form-order-case-select {
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.select-user-document {
    display: inline;
    margin-right: 2rem;
}

.form-service-group {
    display: inline;
}

.form-final-group {
    margin: 2rem;
}

.form-service-checkbox {
    display: inline;
    margin-right: 0.5em;
    transform: scale(1.2);
}

.form-service-label {
    font-size: var(--font-size-xs);
    font-weight: 300;
}

.form-textarea {
    margin: auto;
    min-width: 100%;
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    border-radius: 0.25rem;
    resize: auto;
    border-color: var(--color-light-gray);
}

#matter-dropdown{
    display: none
}