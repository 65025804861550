#signup-container{
    width : 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 5px auto auto 5px;
    overflow-y: scroll;
}

#signup-header-block{
    height: 100%;
    width: 100%;
    background: #585858;
    max-height: 5px;
}
#signup-footer-block{
    background: #585858;
    width: 100%;
    height: 50px;
}

/* .footer-image{
    max-height: 300px;
    overflow: hidden;
} */

.strength {
    font-size: 10pt;
    font-weight: 600;
}
.strength.very-weak {
    color : #ca0000;
}
.strength.indicator.internal.very-weak {
    background : #ca0000;
}
.strength.weak {
    color : #d37000;
}
.strength.indicator.internal.weak {
    background : #d37000;
}
.strength.moderate{
    color : #ddbc00;
}
.strength.indicator.internal.moderate{
    background : #ddbc00;
}
.strength.strong{
    color : #c1d600;
}
.strength.indicator.internal.strong{
    background : #c1d600;
}
.strength.very-strong{
    color : #25b800;
}
.strength.indicator.internal.very-strong{
    background : #25b800;
}

div.strength.indicator {
    position: relative;
    display: inline-block;
    margin: auto;
    height: 12px;
    max-height: 12px;
    background: #f5f5f5;
    padding: 0px;
    border: none;
    border-radius: 3px;
    z-index: 22;
}

div.strength.indicator.internal {
    height: 10px;
    margin: 0px;
    position: absolute;
    left: 0px;
    z-index: 23;
}


.signup-background-image{
    max-width: 100%;
} 

.form-heading {
    margin-top: 0rem;
}

.attempt-error {
    color : var(--color-ontrial-red);
}

.full {
    height: 100%;
}

label {
    font-size: var(--font-size-xs);
}

.required-label {
    color: red;
}

/* #login-box {
    background: white;
    opacity: .6;
    border-radius: .5em;
    padding: 20px;
    height: 380px;
    width: 380px;
    z-index:0;
    display:inline-block;
} */
/* #login-form {
    position:relative;
    margin-top: -360px;
    z-index:1;
} */
/* #create-account-box {
    background: white;
    opacity: .6;
    border-radius: .5em;
    padding: 20px;
    height: 540px;
    width: 380px;
    z-index:0;
    display:inline-block;
}
#create-account-form {
    position:relative;
    margin-top: -540px;
    z-index:1;
} */