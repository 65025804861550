.new-items-row {
    margin-top: -1rem;
}

.rolodex-quickview {
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.rolodex-quickview-inner{
    max-height: 100%;
    padding-right : 1rem;
    padding-left : 1rem;
}
#new-items-div {
    padding-left: 1rem;
}
.new-item-button {
    position: relative;
    margin-right: 1rem;
    z-index: 40;
    /* border-left: 4px solid #74ad1b; */
    border-radius: .5rem;
    background-color: white;
}

#orders-menu-container {
    padding-top: 1rem;
    padding-left: 1rem;
    min-height: 6rem;
    /* max-width: 40rem; */
    /* margin: auto; */
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 2.5rem;
    max-height: 2.5rem;
}
/* 
#form-click-catch {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
    width: 1000%;
    height: 1000%;
    background: transparent;
} */

#orders-main {
    /* z-index: -1; */
    /* background-color: black; */
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: 100%;
    padding-bottom: 2rem;
}

@media only screen and ( max-width : 900px ){
    #orders-main {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }
}


#orders-left {
    max-height: 100%;
    padding: 0 1.5rem 1.5rem 3rem;
    display: grid;
    grid-template-rows: 5rem 3rem auto;
    overflow-y: hidden;
}

#orders-right {
    padding: 0 3.0rem 1.5rem 1.5rem;
}

.orders-menu-item {
    font-size: 15pt;
    font-weight: 400;
    cursor: pointer;
    padding-bottom: .5rem;
    grid-column: span 1;
    transition: font-weight 0.125s;
}
.orders-menu-item:hover {
    font-weight: 600;
}
@media only screen and ( max-width : 1200px ){
    .orders-menu-item{
        font-size: 12pt;
    }
}
@media only screen and ( max-width : 1050px ){
    .orders-menu-item{
        font-size: 11pt;
    }
}
@media only screen and ( max-width : 800px ){
    .orders-menu-item{
        font-size: 10pt;
    }
}


@keyframes activeBorder {
    from {
        border-bottom: 0px solid var(--color-dark-gray);
        font-weight: 400;
    }
    to {
        border-bottom: 3px solid var(--color-dark-gray);
        font-weight: 600;
    }
}

.menu-item-active {
    font-weight: 600;
    /*animation-name: activeBorder;*/
    /* animation-duration: 0.125s; */
    text-decoration: underline;
    color: var(--color-ontrial-blue);
    /* animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); */
}

@media only screen and ( min-width : 1100px ){
    #orders-main{
        max-width: 1480px;
        margin: auto;
    }
}