.card {
    background: var(--color-white);
    opacity: .7;
    margin-bottom: 0.5rem;
    box-shadow: var(--shadow-middle);
    z-index: 200 !important;
    padding: 1rem;
    padding-top: 0.5rem;
    /* max-height: 100%; */
    border-radius: 0.5rem;
    border: 1px solid var(--color-light-gray);
    transition: border 0.25s ease-out, margin 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    /* border-left: 8px solid var(--color-middle-gray); */
}

.card.active {
    border-left: 4px solid #74ad1b;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    opacity: 1;
    box-shadow: var(--shadow-lifted);
}

@keyframes panelopen {
    from {
        height: 0px;
        min-height: 0px;
        max-height: 0px;
    }
    to {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }
}

.card.card-panel {
    margin-top: 1.5rem;
    opacity: 1;
    padding: 1.5rem;
    padding-bottom: 2rem;
    min-height: 520px;
    max-height: 100%;
    overflow-y: scroll;
    z-index: 17;
    animation-name: panelopen;
    animation-duration: 0.125s;
    animation-timing-function: ease-out;
}

.card-body {
    display: grid;
    grid-template-columns: 60% 40%;
}

.order-link {
    font-size: 10pt;
    margin-left: 0.5rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    color: var(--color-ontrial-blue);
    display: block;
}

/* Basic card class */

.card-bg-light {
    background: var(--color-white);
    margin-bottom: 0.5rem;
    border-left: 8px solid var(--color-middle-gray);
    box-shadow: var(--shadow-middle);
    z-index: 5;
}

/* Panel class ( right side display view ) */

.card-panel-section {
    margin-bottom: 0.5rem;
}

/* Client Metadata display in card panel */

.card-panel-client-meta {
    margin-left: 1rem;
    font-size: 10pt;
    line-height: 10pt;
    font-weight: 300;
    margin-top: 0rem;
    margin-bottom: 0.25rem;
}

.card-panel-rolodex {
    max-height: 55%;
    overflow-y: scroll;
    overflow-x: hidden;
    /* border-bottom: solid 1px var(--color-middle-gray);  */
}

.card-panel-rolodex.not-top {
    border-top: solid 1px var(--color-middle-gray);
}

.rolodex {
    box-shadow: var(--shadow-light);
}

/* Card 'nametag' */

.card-name {
    margin-top: 0rem;
}

/* Margin adjustment for order card nametag */

.card-order-name {
    margin-bottom: 0.25rem;
}

.card-panel-order-case-label, .card-panel-order-label {
    font-weight: 300;
    margin-right: 1rem;
}

.card-order-name {
    font-weight: 500;
}

.card-order-label {
    font-size: 11pt;
    font-weight: 300;
}

.card-order-status {
    font-size: 10pt;
    font-weight: 600;
}

.card-order-status.order-open {
    color: var(--color-ontrial-blue)
}

.card-order-status.order-closed {
    color: var(--color-ontrial-red)
}

.card-order-note {
    border-left: solid 2px var(--color-middle-gray);
    /* border-top-right-radius:20px;
    border-bottom-right-radius: 0.6rem; */
    border-radius: 2px;
    padding: 0.5rem;
    padding-left: 1rem;
    font-weight: 300;
    background: var(--color-lightest-gray)
}

.card-case-open-date {
    margin-top: 0rem;
    font-weight: 200;
    font-size: 12pt;
}

.card-order-meta-label {
    margin-top: 0rem;
    font-weight: 200;
    font-size: 10pt;
}

.card-case-order-link {
    font-size: 10pt;
    margin-left: 0.5rem;
}

.active-side {
    border-left: 4px solid #74ad1b;
}

.document-wrapper {
    /* border-bottom: 1px solid var(--color-light-gray); */
    padding-left: 1rem;
}

.document-title {
    font-weight: 500;
    margin-right: 2rem;
}

.document-type {
    font-size: 9pt;
}

.right {
    float: right;
}

.close {
    margin-bottom: 0%;
}

img.clio-logo {
    max-width: 150px;
    display: inline-block;
}

div.card-variant-account{
    min-height: 0 !important;

}